/**
 * Importación de las dependencias del proyecto
 */
import React from 'react';
import { Descriptions, Select, DatePicker, Button, Space } from 'antd';

import TYPES from './map_event_types';
import { useAudit } from './context';

// Componente de rango de fechas
const { RangePicker } = DatePicker;
// Variable con el mapeo de los tipos de eventos.
const selectTypes = Object.getOwnPropertyNames(TYPES).map(type => ({ label: TYPES[type], value: type }));

/**
 * Componente de función que renderiza los campos de filtros para recuperar
 * los eventos del log
 * @returns {React.ReactNode}
 */
export const EventFields = () => {

    // Obtiene una instancia del contexto del log de auditoria
    const { users, customers, eventType, eventDate, isLoading, isAdmin } = useAudit();

    /**
     * Función que escucha los cambios de selección de fechasl evento
     * @param {[Moment, Moment]} param0 
     */
    const onChangeEventDate = ([start, end]) => {
        if (start && end) {
            eventDate.set({ start: start.clone(), end: end.clone() });
        }
    };

    console.log(eventDate.start, eventDate.end); // Asegúrate de que son instancias de Moment y no undefined.

    /**
     * Función que escucha los cambios de selección del tipo de evento
     * @param {String} value 
     */
    const onChangeEventType = value => eventType.set(value ? [value] : []);
    /**
     * Función que escucha los cambios de usuario que generó el tipo de evento
     * @param {String} value 
     */
    const onChangeEventUser = value => users.set({ ...users, selected: value ? [value] : [] });
    /**
     * Función que escucha los cambio de empresa del evento
     * @param {String} value 
     */
    const onChangeEventCustomer = value => customers.set({ ...customers, selected: value ? [value] : [] });
    /**
     * Función que se ejecuta cuando se filtra por todos los tipos de evento
     */
    const onAllEventTypes = () => eventType.set([]);
    /**
     * Función que se ejecuta cuando se filtra por todos los usuarios
     */
    const onAllEventUsers = () => users.set({ ...users, selected: [] });
    /**
     * Función que se ejecuta cuando se filtra por todas las empresas
     */
    const onAllEventCompanies = () => customers.set({ ...customers, selected: [] });

    // Retorna el componente con los campos de filtrado
    return (
        <Descriptions bordered size = "small" title = 'Parametros de busqueda'
            column = {2}
            extra = {[
                <Space key = "s1" > <Button key = "b1" type = "dashed" onClick = { onAllEventTypes } > Todos los eventos </Button> </Space>,
                <Space key = "s2" > <Button key = "b2" type = "dashed" onClick = { onAllEventUsers } > Todos los usuarios </Button> </Space>,
                <Space key = "s3" > <Button key = "b3" type = "dashed" onClick = { onAllEventCompanies } > Todas las empresas </Button> </Space>
            ]} >
            <Descriptions.Item label = "Tipo de evento" >
                <Select allowClear placeholder = "Todos los tipos de evento"
                    loading = { isLoading }
                    bordered = { false }
                    maxTagCount = {1}
                    value = { eventType.types }
                    onChange = { onChangeEventType }
                    style = {{ width: '100%' }}  >
                    { selectTypes.map(({ label, value }, index) => <Select.Option key = { index } value = { value } > { label } </Select.Option> )}
                </Select>
            </Descriptions.Item>
            <Descriptions.Item label = "Usuario del evento" >
                <Select allowClear placeholder = "Todos los usuarios"
                    disabled = { !isAdmin }
                    loading = { isLoading }
                    bordered = { false }
                    maxTagCount = {1}
                    value = { users.selected }
                    onChange = { onChangeEventUser }
                    style = {{ width: '100%' }}  >
                    { users.source.map((user, index) => (
                        <Select.Option key = { index } value = { user?.uid } >
                            { user?.['Nombre completo'] }
                        </Select.Option>
                    ))}
                </Select>
            </Descriptions.Item>
            <Descriptions.Item label = "Empresa" >
                <Select allowClear placeholder = "Todas las empresas"
                    disabled = { !isAdmin }
                    loading = { isLoading }
                    bordered = { false }
                    maxTagCount = {1}
                    value = { customers.selected }
                    onChange = { onChangeEventCustomer }
                    style = {{ width: '100%' }}  >
                    { customers.source.map((customer, index) => (
                        <Select.Option key = { index } value = { customer?.cid } >
                            { customer?.['Razon social'] }
                        </Select.Option>
                    ))}
                </Select>
            </Descriptions.Item>
            <Descriptions.Item label = "Fechas del evento" >
                <RangePicker defaultValue = {[eventDate.start, eventDate.end]}
                    bordered = { false }
                    onChange = { onChangeEventDate }
                    allowClear = { false } />
            </Descriptions.Item>
        </Descriptions>
    );
};